import './styles/App.css';
import twitterLogo from './assets/twitter-logo.svg';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import myEpicNft from './utils/MyEpicNFT.json';

// Constants
const CREDIT_TWITTER_HANDLE = '_buildspace';
const MY_TWITTER = 'dnachman';
const TWITTER_LINK = `https://twitter.com/`;
const OPENSEA_LINK = '';
const TOTAL_MINT_COUNT = 50;
const CONTRACT_ADDRESS = '0x07D7259BeBc28681f484557333087Ec9fC2d57A0';

const App = () => {
  const [currentAccount, setCurrentAccount] = useState('');

  console.log(`${OPENSEA_LINK} : ${TOTAL_MINT_COUNT}`); // to get rid of warnings

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log('Make sure you have metamask!');
      return;
    } else {
      console.log(`We have the ethereum object: ${ethereum}`);
    }

    // check if we're authorized
    const accounts = await ethereum.request({ method: 'eth_accounts' });

    // grab first one
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log(`Found an authorized account: ${account}`);
      setCurrentAccount(account);

      // in case already connected, need to set this up
      setupEventListener();
    } else {
      console.log('No authorized account found.');
    }
  };

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert('Metamask is needed to proceed!');
        return;
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' });
      console.log('Connected', accounts[0]);
      setCurrentAccount(accounts[0]);

      setupEventListener();
    } catch (error) {
      console.log(error);
    }
  };

  const setupEventListener = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          myEpicNft.abi,
          signer
        );

        // catch the event when our contract throws it
        connectedContract.on('NewEpicNFTMinted', (from, tokenId) => {
          console.log(from, tokenId.toNumber());
          alert(
            `Hey there! We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea. Here's the link: https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}`
          );
        });

        console.log('Setup event listener!');
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const askContractToMintNft = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          myEpicNft.abi,
          signer
        );

        console.log('Going to pop wallet now to pay gas...');
        let nftTxn = await connectedContract.makeAnEpicNFT();

        console.log('Mining... please wait');

        await nftTxn.wait();

        console.log(
          `Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`
        );
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Render Methods
  const renderNotConnectedContainer = () => (
    <button
      onClick={connectWallet}
      className='cta-button connect-wallet-button'>
      Connect to Wallet
    </button>
  );

  const renderConnectedContainer = () => (
    <button
      onClick={askContractToMintNft}
      className='cta-button connect-wallet-button'>
      Mint NFT
    </button>
  );

  useEffect(() => {
    checkIfWalletIsConnected();
  });

  return (
    <div className='App'>
      <div className='container'>
        <div className='header-container'>
          <p className='header gradient-text'>My NFT Collection</p>
          <p className='sub-text'>
            Each unique. Each beautiful. Discover your NFT today.
          </p>
          {currentAccount === ''
            ? renderNotConnectedContainer()
            : renderConnectedContainer()}
        </div>
        <div className='footer-container'>
          <img alt='Twitter Logo' className='twitter-logo' src={twitterLogo} />
          <div className='footer-text'>
            &nbsp; built by &nbsp;
            <a
              className='footer-text'
              href={`${TWITTER_LINK}/${MY_TWITTER}`}
              target='_blank'
              rel='noreferrer'>{`@${MY_TWITTER}`}</a>
            &nbsp; with help from &nbsp;
            <a
              className='footer-text'
              href={`${TWITTER_LINK}/${CREDIT_TWITTER_HANDLE}`}
              target='_blank'
              rel='noreferrer'>{`@${CREDIT_TWITTER_HANDLE}`}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
